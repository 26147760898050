<template>
    <repair-manage :typePage="typePage"></repair-manage>
</template>

<script>
import RepairManage from "@/views/modules/collection/business/repair/repairManage.vue";

export default {
    name: "repairRecord",
    components: {RepairManage},
    data(){
        return{
            typePage:'repairRecord',
        }
    },
}
</script>

<style scoped>

</style>